import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import "../App.css";
import "../styles/order.css";
import axios from "../utils/webcall";
import { API_ENDPOINT } from "../App";
import { CiSearch } from "react-icons/ci";

import Header from "../components/header";
import Footer from "../components/footer";
import Card from "../components/cards";
import Loading from "../components/loading";
import notFoundImage from "../images/notFound.png";
import CartNotification from "../components/CartNotification"; // Import the CartNotification component
import { filterItems } from "../utils";

import { useLocation } from "react-router-dom";

const Order = () => {
    const [items, setItems] = useState([]);
    const [originalItems, setOriginalItems] = useState([]);
    const [categories, setCategories] = useState({});
    const [search, setSearch] = useState(""); // For displaying in input field
    const [loading, setLoading] = useState(true);
    const [showSearch, setShowSearch] = useState(false);
    const [error, setError] = useState(null);
    const searchInputRef = useRef(null);

    const location = useLocation();

    // Memoize categoryMapping to avoid recreating it on every render
    const categoryMapping = useMemo(() => ({
        MC: "Main Course",
        DE: "Desserts",
        BE: "Beverages",
        BR: "Breads",
    }), []);

    // Define categorizeItems before using it in useEffect
    const categorizeItems = useCallback((items) => {
        const categories = items.reduce((acc, item) => {
            if (!acc[item.item_category]) {
                acc[item.item_category] = [];
            }
            acc[item.item_category].push(item);
            return acc;
        }, {});
        setCategories(categories);
    }, []);

    useEffect(() => {
        if (location.state && location.state.showSearch) {
            setShowSearch(true);
        }
        if (showSearch && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [location.state, showSearch]);

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/inv/`)
          .then(response => {
            const data = response.data.data;
            setItems(data);
            setOriginalItems(data);
            categorizeItems(data);
            setLoading(false);
            setError(null);
          })
          .catch(error => {
            console.error(error);
            setLoading(false);
            setError("Uh Oh! We got an Error, try reloading while we work on it...");
          });
    }, [categorizeItems]);

    useEffect(() => {
        if (showSearch && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [showSearch]);

    // Function to handle search input change
    const handleSearchChange = (event) => {
        const value = event.target.value;
        const normalizedValue = value.replace(/\s+/g, ' ').trim(); // Normalize spaces
        setSearch(value);
        searchForItem(normalizedValue); // Filter using normalized value
    };

    const searchForItem = useCallback((query) => {
        const filteredItems = filterItems(originalItems, query, categoryMapping);
        setItems(filteredItems);
        categorizeItems(filteredItems);
    }, [originalItems, categorizeItems, categoryMapping]);

    const handleSearchToggle = () => {
        if (showSearch && items.length === 0) {
            setSearch("");
            setItems(originalItems);
            categorizeItems(originalItems);
        }
        setShowSearch(!showSearch);
    };

    const hasNoResults = Object.values(categories).every(categoryItems => categoryItems.length === 0);

    return (
        <div id="PageContainer">
            <Header onSearchToggle={handleSearchToggle} />
            <div id="BodyContent">
                <div
                    id="searchbutton"
                    className={`searchbutton ${showSearch ? 'show' : 'hide'}`}
                >
                    <div className="searchIcon">
                        <CiSearch style={{ color: "gold" }} aria-hidden="true" />
                    </div>
                    <input 
                        placeholder="Search for a Snack..." 
                        onChange={handleSearchChange}
                        value={search}
                        ref={searchInputRef}
                        aria-label="Search input"
                        tabIndex={showSearch ? "0" : "-1"} // Manage tab accessibility based on showSearch
                    />
                    <div className="emptySearch">&nbsp;</div>
                </div>
                {
                    loading ? (
                        <div className="loadingContainer">
                            <Loading />
                        </div>
                    ) : error ? (
                        <div className="errorContainer">
                            <img src={notFoundImage} alt="Error" className="notFoundImage" /><br/>
                            <span>{error}</span>
                        </div>
                    ) : hasNoResults ? (
                        <div className="notFoundContainer">
                            <img src={notFoundImage} alt="No items found" className="notFoundImage" /><br/>
                            <span>No items found that match your search</span>
                        </div>
                    ) : (
                        Object.entries(categories).map(([category, items]) => (
                            <div key={category}>
                                <div className="ItemTitles stylishHeading">{categoryMapping[category] || category}</div>
                                <div className="cardHolder">
                                    {
                                        items.map(item => (
                                            <Card
                                                name={item.item_name}
                                                key={item.id}
                                                price={item.price}
                                                desc={item.description}
                                                nonveg={item.nonveg}
                                                imgsrc={item.item_img}
                                                serves={item.serves}
                                                elemid={item.id}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    )
                }
            </div>
            <Footer />
            <CartNotification /> {/* Add CartNotification component here */}
        </div>
    );
}

export default Order;
