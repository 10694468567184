import React, { useEffect, useState } from 'react';
import Header from "../components/header";
import Footer from "../components/footer";
import { useSearchParams } from "react-router-dom";
import "../styles/order_status.css";
import axios from "../utils/webcall";
import { API_ENDPOINT } from '../App';
import SyncLoader from "react-spinners/SyncLoader";

const OrderSuccessPage = () => {
    const [orderDetails, setOrderDetails] = useState(null);
    const [orderID, setOrderID] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [deliveryAddress, setDeliveryAddress] = useState();

    useEffect(() => {
        let temp = searchParams.get('order_id');
        setOrderID(temp);
        let getOrderInfo = async (params) => {
            let payload = {
                'order_id': temp
            }
            let res = await axios.post(`${API_ENDPOINT}/order/info`, payload);
            console.log(res.data);
            let data = res.data;
            setDeliveryAddress(data.address);
            setOrderDetails(data);
        }
        getOrderInfo();
    }, [])
    console.log(orderDetails);
    return (
        <>
            {orderDetails == null ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw'
                }}>
                    <SyncLoader />

                </div>
            ) : (
                <div id='PageContainer'>
                    <Header />
                    <div id="orderCompletionPage">
                        <div id='orderStatusHeaderTop'>Order placed</div>
                        <div id='orderStatusMessage'>Your Order is being prepared</div>
                        <div id='orderPageSubMessage'>Get excited. You are going to get your order soon.</div>
                        <div id='orderPageDeliveringTo'>Delivering to</div>
                        <div id='orderDeliveryAddress'>{orderDetails.address}</div>
                        <div id='orderID1'>Your Order ID is</div>
                        <div id='orderID2'>#{orderID}</div>
                        {/* <div id='orderItemsNb'>{orderDetails.items.length} Items</div> */}
                        <div id='orderItems'>Item details</div>
                        {Object.keys(orderDetails.items).map((item, index) => {
                            console.log(item, index);
                            return (<>
                                <div id='orderItems'>{orderDetails.items[item].name}</div>
                                <div id='orderItems'>{orderDetails.items[item].serves}</div>
                            </>)
                        })}
                        <div id='orderNeedHelp'><a>Need help?</a></div>
                    </div>
                    <Footer />
                </div>)
            }
        </>
    );
}

export default OrderSuccessPage;
